import { combineReducers } from "redux";
import user from "./user";
import toast from "./toast";
import campsites from "./campsites";

export default combineReducers({
  user,
  toast,
  campsites,
});
