import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import styles from "./NavBar.module.scss";
import { bindActionCreators } from "redux";
import { setUser, logOut } from "../../actions/user";
import { connect } from "react-redux";
import { isEmpty } from "lodash";
import AccountBubble from "./AccountBubbble";
// import GoogleSignIn from "../GoogleSignIn";
import { MdMenu, MdClose } from "react-icons/md";

const mapStateToProps = (state) => ({
  user: state.user,
  fullState: state,
  campsites: state.campsites,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ setUser, logOut }, dispatch);

class NavBar extends Component {
  constructor() {
    super();
    this.state = {
      mobileMenuOpen: false,
    };
  }

  goTo = (route) => {
    this.props.history.push(route);
  };

  render() {
    const { user, campsites } = this.props;
    const { accountType } = campsites;

    return (
      <div className={styles.yellowStrip}>
        {this.state.mobileMenuOpen ? (
          <div className={styles.drawer}>
            <div className={styles.drawerContentContainer}>
              <a href="/pricing">
                <div className={styles.drawerItem}>Pricing</div>
              </a>
              <div className={styles.spacer}></div>
              <a href="/shah">
                <div className={styles.drawerItem}>See an Example</div>
              </a>
              <div className={styles.spacer}></div>
              {isEmpty(this.props.user) ? null : (
                <a href="/dashboard/account">
                  <div className={styles.drawerItem}>My Account</div>
                </a>
              )}
            </div>
          </div>
        ) : null}
        <div className={styles.navContentContainer}>
          <div onClick={() => this.goTo("/")} className={styles.home}>
            <div className={styles.logoContainer}>
              <img
                className={styles.logoContainer}
                alt="logo"
                src="https://i.imgur.com/6Orc87w.png"
              ></img>
            </div>
            <div className={styles.campah}>campah</div>
          </div>
          <div className={styles.rightSide}>
            {accountType === 1 ? (
              <div
                onClick={() => this.goTo("/dashboard/account")}
                className={styles.navItemPRO}
              >
                PRO
              </div>
            ) : null}

            <div
              onClick={() => this.goTo("/pricing")}
              className={styles.navItem}
            >
              Pricing
            </div>
            <div onClick={() => this.goTo("/shah")} className={styles.navItem}>
              See an Example
            </div>

            {/* {isEmpty(this.props.user) ? (
              <div className={styles.googleloginContainer}>
                <GoogleSignIn
                  label="Sign Up"
                  navbar={true}
                  successRoute="/start"
                ></GoogleSignIn>
              </div>
            ) : null} */}

            {isEmpty(user) ? null : <AccountBubble />}
            <div
              onClick={() =>
                this.setState({ mobileMenuOpen: !this.state.mobileMenuOpen })
              }
              className={styles.mobileNav}
            >
              {this.state.mobileMenuOpen ? (
                <MdClose className={styles.menuIcon}></MdClose>
              ) : (
                <MdMenu className={styles.menuIcon}></MdMenu>
              )}
            </div>
          </div>
          <div></div>
        </div>
      </div>
    );
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NavBar));
