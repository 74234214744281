import React, { FunctionComponent } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import s from "./AccountBubble.module.scss";

const AccountBubble: FunctionComponent<{ props: any }> = (any) => {
  const user = useSelector((state: any) => state.user);
  const history = useHistory();

  return (
    <div
      className={s.bubbleContainer}
      onClick={() => history.push("/dashboard/account")}
    >
      <img
        alt="my account bubbble"
        src={user.user_avatar_url}
        className={s.profileImage}
      ></img>
    </div>
  );
};

export default AccountBubble;
