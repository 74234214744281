import React, { useState, useEffect } from "react";
import styles from "./UserPage.module.scss";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import { createUseStyles } from "react-jss";
import {
  FaTwitter,
  FaYoutube,
  FaLinkedinIn,
  FaInstagram,
} from "react-icons/fa";
import { isEmpty } from "lodash";
import { useHistory } from "react-router";

const UserPage = (props) => {
  useEffect(() => {}, [props.theme]);
  const history = useHistory();

  const getPrimaryColor = () => {
    if (props.source === "dashboard") {
      if (props.theme && props.theme.primary) {
        return props.theme.primary;
      }
    } else return theme.primary;
  };

  const getSecondaryColor = () => {
    if (props.accountType === 0) {
      return "none";
    }
    if (props.source === "dashboard") {
      if (props.theme && props.theme.secondary) {
        return props.theme.secondary;
      }
    } else return theme.secondary;
  };

  const getTextColor = () => {
    if (props.source === "dashboard") {
      if (props.theme && props.theme.textColor) {
        return props.theme.textColor;
      }
    } else return theme.textColor;
  };

  const getBioColor = () => {
    if (props.source === "dashboard") {
      if (props.theme && props.theme.bioColor) {
        return props.theme.bioColor;
      }
    } else return theme.bioColor;
  };

  const useStyles = createUseStyles({
    tertColorTertHover: (props) => ({
      color: props.theme.tertiary,
      "&:hover": {
        color: props.theme.tertiary,
      },
    }),
    tertBackgroundSecHover: (props) => ({
      background: props.theme.tertiary,
      "&:hover": {
        background: props.theme.secondary,
      },
    }),
    textColorTertHover: (props) => ({
      color: props.theme.textColor,
      "&:hover": {
        color: props.theme.textHover,
      },
    }),
  });

  const [bioHeader, setBioHeader] = useState("");
  const [bioDescription, setBioDescription] = useState("");
  const [social, setSocial] = useState({});
  const [profileImageLink, setProfileImageLink] = useState("");
  const [links, setLinks] = useState([]);
  const [theme, setTheme] = useState({
    name: "goomy",
    primary: "black",
    secondary: "black",
    tertiary: "black",
    bioColor: "black",
    textColor: "black",
    textHover: "black",
  });

  const { siteName } = useParams();

  const classes = useStyles({ ...props });

  useEffect(() => {
    const siteNameParam = siteName;
    if (props.source !== "dashboard") {
      fetch(`/api/getPublicCampsiteInfo/${siteNameParam}`)
        .then((response) => response.json())
        .then((data) => {
          setBioHeader(data.campsite.bioHeader);
          setBioDescription(data.campsite.bioDescription);
          setProfileImageLink(data.campsite.profileImageLink);
          setSocial(data.campsite.social);
          setLinks(data.campsite.links);
          setTheme(data.campsite.theme);
        });
    }
  }, [props.source, siteName]);

  const renderHelmet = () => {
    return (
      <Helmet>
        <title>Campah</title>
        <meta
          name="description"
          content="Release, track and measure a Hacker News post or release"
        />
        <meta
          name="keywords"
          content="Hacker news, comements, release management, software engineer"
        />
        <meta property="og:url" content="https://www.failflow.com/hnrd" />
        <meta property="og:title" content="Hacker News Release Dashboard" />
        <meta
          property="og:description"
          content="Release, track and measure a Hacker News post or release"
        />
        <meta property="og:image" content="https://i.imgur.com/nWR8btF.png" />
        <meta
          property="og:image:url"
          content="https://i.imgur.com/nWR8btF.png"
        />
        <meta
          property="twitter:image"
          content="https://i.imgur.com/nWR8btF.png"
        />
      </Helmet>
    );
  };

  const cleanUpUrl = (url) => {
    if (!url.startsWith("http")) {
      return "https://" + url;
    } else return url;
  };

  const renderCard = (el) => {
    if (el.description !== "" && el.thumbnailLink !== "") {
      return (
        <a
          key={el.id}
          href={cleanUpUrl(el.url)}
          target="_blank"
          rel="noreferrer"
        >
          <div
            className={
              getConditionalClassName("card") +
              " " +
              classes.tertBackgroundSecHover +
              " " +
              classes.textColorTertHover
            }
          >
            <div className={getConditionalClassName("cardIcon")}>
              {el.thumbnailLink ? (
                <img
                  className={getConditionalClassName("cardIconImage")}
                  src={el.thumbnailLink}
                  alt="Software Social"
                ></img>
              ) : null}
            </div>
            <div>
              <div className={getConditionalClassName("cardTitle")}>
                {el.title}
              </div>
              <div className={getConditionalClassName("cardDescription")}>
                {el.description}
              </div>
            </div>
          </div>{" "}
        </a>
      );
    } else if (el.description === "" && el.thumbnailLink === "") {
      return (
        <a
          key={el.id}
          href={cleanUpUrl(el.url)}
          target="_blank"
          rel="noreferrer"
        >
          <div
            className={
              getConditionalClassName("card") +
              " " +
              classes.tertBackgroundSecHover +
              " " +
              classes.textColorTertHover
            }
          >
            <div className={getConditionalClassName("cardTitleNoDesc")}>
              {el.title}
            </div>
          </div>
        </a>
      );
    } else if (el.description === "" && el.thumbnailLink !== "")
      return (
        <a
          key={el.id}
          href={cleanUpUrl(el.url)}
          target="_blank"
          rel="noreferrer"
        >
          <div
            className={
              getConditionalClassName("card") +
              " " +
              classes.tertBackgroundSecHover +
              " " +
              classes.textColorTertHover
            }
          >
            <div className={getConditionalClassName("cardIconNoDesc")}>
              {el.thumbnailLink ? (
                <img
                  className={getConditionalClassName("cardIconImageNoDesc")}
                  src={el.thumbnailLink}
                  alt="Software Social"
                ></img>
              ) : null}
            </div>
            <div className={getConditionalClassName("cardTitleNoDesc")}>
              {el.title}
            </div>
          </div>
        </a>
      );
    else if (el.description !== "" && el.thumbnailLink === "") {
      return (
        <a
          key={el.id}
          href={cleanUpUrl(el.url)}
          target="_blank"
          rel="noreferrer"
        >
          <div
            className={
              getConditionalClassName("card") +
              " " +
              classes.tertBackgroundSecHover +
              " " +
              classes.textColorTertHover
            }
          >
            <div>
              <div className={getConditionalClassName("cardTitle")}>
                {el.title}
              </div>
              <div className={getConditionalClassName("cardDescription")}>
                {el.description}
              </div>
            </div>
          </div>
        </a>
      );
    }
  };

  const renderLinks = () => {
    if (links && links.length > 0) {
      return (
        <div className={styles.cardContainer}>
          {links.map((el) => renderCard(el))}
        </div>
      );
    } else if (props.links && props.links.length > 0) {
      return (
        <div className={styles.cardContainer}>
          {props.links.map((el) => renderCard(el))}
        </div>
      );
    }
  };

  const renderProfileImage = () => {
    if (profileImageLink !== "") {
      return (
        <img
          className={getConditionalClassName("imageCircle")}
          src={profileImageLink}
          alt="profile"
        ></img>
      );
    } else if (props.profileImageLink) {
      return (
        <img
          className={getConditionalClassName("imageCircle")}
          src={props.profileImageLink}
          alt="profile"
        ></img>
      );
    } else {
      return (
        <img
          className={getConditionalClassName("imageCircle")}
          src="https://i.imgur.com/5p78yOv.jpg"
          alt="profile"
        ></img>
      );
    }
  };

  const renderSocialSection = () => {
    if (props.source !== "dashboard") {
      return (
        <div className={getConditionalClassName("socialSection")}>
          {social && social.twitter && (
            <a
              href={cleanUpUrl(social.twitter)}
              target="_blank"
              rel="noreferrer"
            >
              <FaTwitter
                className={
                  getConditionalClassName("socialIcon") +
                  " " +
                  classes.tertColorTertHover
                }
              />
            </a>
          )}
          {social && social.youtube && (
            <a
              href={cleanUpUrl(social.youtube)}
              target="_blank"
              rel="noreferrer"
            >
              <FaYoutube
                className={
                  getConditionalClassName("socialIcon") +
                  " " +
                  classes.tertColorTertHover
                }
              />
            </a>
          )}
          {social && social.linkedin && (
            <a
              href={cleanUpUrl(social.linkedin)}
              target="_blank"
              rel="noreferrer"
            >
              <FaLinkedinIn
                className={
                  getConditionalClassName("socialIcon") +
                  " " +
                  classes.tertColorTertHover
                }
              />
            </a>
          )}
          {social && social.instagram && (
            <a
              href={cleanUpUrl(social.instagram)}
              target="_blank"
              rel="noreferrer"
            >
              <FaInstagram
                className={
                  getConditionalClassName("socialIcon") +
                  " " +
                  classes.tertColorTertHover
                }
              />
            </a>
          )}
        </div>
      );
    } else {
      return (
        <div className={getConditionalClassName("socialSection")}>
          {props.social && props.social.twitter && (
            <a
              href={cleanUpUrl(props.social.twitter)}
              target="_blank"
              rel="noreferrer"
            >
              <FaTwitter
                className={
                  getConditionalClassName("socialIcon") +
                  " " +
                  classes.tertColorTertHover
                }
              />
            </a>
          )}
          {props.social && props.social.youtube && (
            <a
              href={cleanUpUrl(props.social.youtube)}
              target="_blank"
              rel="noreferrer"
            >
              <FaYoutube
                className={
                  getConditionalClassName("socialIcon") +
                  " " +
                  classes.tertColorTertHover
                }
              />
            </a>
          )}
          {props.social && props.social.linkedin && (
            <a
              href={cleanUpUrl(props.social.linkedin)}
              target="_blank"
              rel="noreferrer"
            >
              <FaLinkedinIn
                className={
                  getConditionalClassName("socialIcon") +
                  " " +
                  classes.tertColorTertHover
                }
              />
            </a>
          )}
          {props.social && props.social.instagram && (
            <a
              href={cleanUpUrl(props.social.instagram)}
              target="_blank"
              rel="noreferrer"
            >
              <FaInstagram
                className={
                  getConditionalClassName("socialIcon") +
                  " " +
                  classes.tertColorTertHover
                }
              />
            </a>
          )}
        </div>
      );
    }
  };

  const getConditionalClassName = (key) => {
    if (props.source !== "dashboard") {
      return styles[key];
    } else if (props.source === "dashboard" && props.previewSize) {
      return styles[key + props.previewSize];
    }
  };

  const getPracClassName = () => {
    for (const key in props.social) {
      if (props.social.hasOwnProperty(key)) {
        if (props.social[key] !== "") {
          return "prac";
        }
      }
    }

    return "pracEmpty";
  };

  const renderUnclaimed = () => {
    return (
      <div className={styles.unclaimedContainer}>
        <div className={styles.unclaimedContentContainer}>
          <div
            className={styles.logo}
            onClick={() => {
              history.push("/");
            }}
          >
            campah
          </div>
          <div className={styles.unclaimedDescription}>
            This page hasn't been claimed yet, or has been deleted. Make an
            account to claim it now.
          </div>
          <div
            className={styles.claimButton}
            onClick={() => {
              history.push("/");
            }}
          >
            Claim Page
          </div>
          <div className={styles.unclaimedWarning}>
            If you received this link through an unsolicited email or message,
            please report it to shah@failflow.com
          </div>
        </div>
      </div>
    );
  };

  const renderUnpublished = () => {
    return (
      <div className={styles.unclaimedContainer}>
        <div className={styles.unclaimedContentContainer}>
          <div
            className={styles.logo}
            onClick={() => {
              history.push("/");
            }}
          >
            campah
          </div>
          <div className={styles.unclaimedDescription}>
            This page hasn't been published.
          </div>
          <div
            className={styles.claimButton}
            onClick={() => {
              history.push("/");
            }}
          >
            Go Home
          </div>
          <div className={styles.unclaimedWarning}>
            If you received this link through an unsolicited email or message,
            please report it to shah@failflow.com
          </div>
        </div>
      </div>
    );
  };

  const renderFork = () => {
    if (props.unclaimed) {
      return renderUnclaimed();
    } else if (props.accountType === 0) {
      return renderUnpublished();
    } else return renderCampsite();
  };

  const renderCampsite = () => {
    return (
      <>
        <div className={getConditionalClassName("above")}>
          <div className={getConditionalClassName("circle")}>
            {renderProfileImage()}
          </div>
        </div>
        <div
          className={getConditionalClassName(getPracClassName())}
          style={{
            background: getPrimaryColor(),
          }}
        >
          {renderSocialSection()}
        </div>
        <div
          className={getConditionalClassName("container")}
          style={{ background: getPrimaryColor() }}
        >
          <div className={getConditionalClassName("contentContainer")}>
            <div
              className={getConditionalClassName("splashHeader")}
              style={{ color: getBioColor() }}
            >
              {bioHeader ? bioHeader : props.bioHeader}

              {props.noEntryYet
                ? "All your content goes here! Fill it in on the left"
                : null}
            </div>
            <div
              className={getConditionalClassName("bioInfo")}
              style={{ color: getBioColor() }}
            >
              {bioDescription ? bioDescription : props.bioDescription}
            </div>
            {renderLinks()}
          </div>
        </div>
        <div className={getConditionalClassName("footer")}>
          <a
            href="/"
            className={classes.tertColorTertHover}
            style={{ background: getPrimaryColor() }}
          >
            {props.hideCampah ? "" : "campah"}
          </a>{" "}
        </div>{" "}
      </>
    );
  };

  return (
    <div
      className={getConditionalClassName("all")}
      style={{
        background: getSecondaryColor(),
      }}
    >
      {renderHelmet()}
      {renderFork()}
    </div>
  );
};

export default UserPage;
