import React, { Component } from "react";
import { withRouter } from "react-router-dom";
// import "./HumanPayForm.css";
import styles from "./Upgrade.module.scss";
import { injectStripe } from "react-stripe-elements";
import { CardElement } from "react-stripe-elements";
import { connect } from "react-redux";

const mapStateToProps = (state) => ({
  user: state.user,
});

class UpgradeStripeForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      name: "",
      loading: false,
      errorMessage: "",
    };
  }

  handleEmailChange = (e) => {
    this.setState({ email: e.target.value });
  };

  handleNameChange = (e) => {
    this.setState({ name: e.target.value });
  };

  createOptions(fontSize, padding) {
    return {
      style: {
        base: {
          color: "#424770",
          fontSize: "14px",
          letterSpacing: "0.025em",
          fontFamily: "Source Code Pro, monospace",
          "::placeholder": {
            color: "#aab7c4",
          },
          padding,
        },
        invalid: {
          color: "#9e2146",
        },
      },
    };
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({ loading: true });
    this.props.stripe.createToken({ type: "card" }).then((out) => {
      const paymentData = {
        token: out.token.id,
        email: this.state.email,
        name: this.state.name,
        period: this.props.period,
      };

      fetch("/api/chargeCampah", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: this.props.user.token,
        },
        body: JSON.stringify(paymentData),
      })
        .then((res) => {
          return res.json();
        })
        .then((json) => {
          if (json.success) {
            this.props.history.push("/dashboard");
          } else {
            this.setState({ loading: false, errorMessage: json.message });
          }
        })
        .catch((err) => console.log(err));
    });
  };

  goBack() {
    this.props.history.push("/dashboard");
  }

  render() {
    return (
      <div>
        <form onSubmit={this.handleSubmit} className={styles.checkoutForm}>
          <label className={styles.paymentLabel}>
            Name
            <div className={styles.inputHolder}>
              <input
                className={styles.nameInput}
                name="name"
                type="text"
                placeholder="Full Name"
                required
                value={this.state.name}
                onChange={this.handleNameChange}
              />
            </div>
          </label>
          <label className={styles.paymentLabel}>
            Email
            <div className={styles.inputHolder}>
              <input
                className={styles.nameInput}
                name="email"
                type="text"
                placeholder="email@example.com"
                required
                autoComplete="email"
                value={this.state.email}
                onChange={this.handleEmailChange}
              />
            </div>
          </label>
          <label className={styles.paymentLabel}>
            Card number
            <CardElement {...this.createOptions("20px")} />
          </label>
          {this.state.errorMessage ? (
            <div className={styles.errorText}>
              Error: {this.state.errorMessage}
            </div>
          ) : null}
          {this.state.loading ? (
            <div className="lds-ring">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          ) : (
            <div className={styles.buttonRow}>
              <button className={styles.payButton}>Confirm</button>

              <div className={styles.cancel} onClick={() => this.goBack()}>
                Cancel
              </div>
            </div>
          )}
        </form>
      </div>
    );
  }
}

export default injectStripe(
  withRouter(connect(mapStateToProps, null)(UpgradeStripeForm))
);
