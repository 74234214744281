import React, { FunctionComponent, useState, useEffect } from "react";
import NavBar from "./NavBar";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { postWrapper, getWrapper } from "../../helpers/fetch";
import s from "./Dashboard.module.scss";
import { v4 as uuidv4 } from "uuid";
import UserPage from "./UserPageDashboard";
import { Helmet } from "react-helmet";

interface Theme {
  name: string;
  primary: string;
  secondary: string;
  tertiary: string;
  bioColor: string;
  textColor: string;
  textHover: string;
}

const Dashboard: FunctionComponent<{ props: any }> = (props) => {
  const history = useHistory();

  const user = useSelector((state: any) => state.user);
  const accountType = useSelector((state: any) => state.campsites.accountType);

  type social = "twitter" | "instagram" | "youtube" | "linkedin";

  useEffect(() => {
    getWrapper("GET", `/api/getCampsiteInfo`, {
      Authorization: user.token,
    }).then((getCampsiteInfoResponse) => {
      const social = getCampsiteInfoResponse.campsite.social;

      if ("campsite" in getCampsiteInfoResponse) {
        setSocialSection({ ...social });
        setSiteName(getCampsiteInfoResponse.campsite.siteName);
        setBioHeader(getCampsiteInfoResponse.campsite.bioHeader);
        setBioDescription(getCampsiteInfoResponse.campsite.bioDescription);
        setProfileImageLink(getCampsiteInfoResponse.campsite.profileImageLink);
        setLinkArray(getCampsiteInfoResponse.campsite.links);
        setTheme(getCampsiteInfoResponse.campsite.theme);
        setUseCustomTheme(getCampsiteInfoResponse.campsite.useCustomTheme);
        setCustomTheme(getCampsiteInfoResponse.campsite.customTheme);
        setHideCampah(getCampsiteInfoResponse.campsite.hideCampah);
      }
    });
  }, [user.token]);

  // BEGIN States

  const [siteName, setSiteName] = useState("");
  const [socialSection, setSocialSection] = useState({
    twitter: "",
    instagram: "",
    youtube: "",
    linkedin: "",
  });
  const [previewSize, setPreviewSize] = useState("desktop");
  const [bioHeader, setBioHeader] = useState("");
  const [bioDescription, setBioDescription] = useState("");
  const [profileImageLink, setProfileImageLink] = useState("");
  // we need this extra piece of state for when we update link thumbnails
  const [theme, setTheme] = useState({
    name: "goomy",
    primary: "black",
    secondary: "black",
    tertiary: "black",
    bioColor: "black",
    textColor: "black",
    textHover: "black",
  });

  const [customTheme, setCustomTheme] = useState({
    primary: "",
    secondary: "",
    tertiary: "",
    bioColor: "",
    textColor: "",
    textHover: "",
  });
  const [linkArray, setLinkArray] = useState([
    { url: "", title: "", description: "", id: "", thumbnailLink: "" },
  ]);

  const [useCustomTheme, setUseCustomTheme] = useState(false);
  const [hideCampah, setHideCampah] = useState(false);

  // END states

  // START api calls

  const setDBUseCustomTheme = async (flag: boolean) => {
    await postWrapper(
      "POST",
      "/api/updateUseCustomTheme",
      {
        Authorization: user.token,
        "Content-Type": "application/json",
      },
      JSON.stringify({ useCustomTheme: flag })
    );
  };

  const setDBHideCampah = async (flag: boolean) => {
    await postWrapper(
      "POST",
      "/api/updateHideCampah",
      {
        Authorization: user.token,
        "Content-Type": "application/json",
      },
      JSON.stringify({ hideCampah: flag })
    );
  };

  const updateCampsiteSocial = async (platform: social) => {
    await postWrapper(
      "POST",
      "/api/updateSocial",
      {
        Authorization: user.token,
        "Content-Type": "application/json",
      },
      JSON.stringify({ platform, updatedValue: socialSection[platform] })
    );
  };

  const updateCampsiteBio = async () => {
    await postWrapper(
      "POST",
      "/api/updateBio",
      {
        Authorization: user.token,
        "Content-Type": "application/json",
      },
      JSON.stringify({ bioHeader, bioDescription })
    );
  };

  const updateCampsiteCustomTheme = async () => {
    await postWrapper(
      "POST",
      "/api/updateCustomTheme",
      {
        Authorization: user.token,
        "Content-Type": "application/json",
      },
      JSON.stringify({ customTheme })
    );
  };

  const updateCampsiteProfImage = async (profileImageLink: string) => {
    await postWrapper(
      "POST",
      "/api/updateProfImage",
      {
        Authorization: user.token,
        "Content-Type": "application/json",
      },
      JSON.stringify({ profileImageLink })
    );
  };

  const updateTheme = async (theme: Theme) => {
    await postWrapper(
      "POST",
      "/api/updateTheme",
      {
        Authorization: user.token,
        "Content-Type": "application/json",
      },
      JSON.stringify({ theme })
    );
  };

  // END api calls

  const noEntryYet = () => {
    if (
      !profileImageLink &&
      !bioHeader &&
      !bioDescription &&
      socialSection !==
        { twitter: "", instagram: "", youtube: "", linkedin: "" } &&
      !linkArray
    ) {
      return true;
    } else return false;
  };

  const updateLocalAndDBTheme = (theme: Theme, premium = false) => {
    if (premium && accountType !== 1) {
      return;
    }
    updateTheme(theme);
    setTheme(theme);
  };
  // START render functions
  const getDisabled = () => {
    if (accountType !== 1) {
      return s.disabledHover;
    }
  };

  const renderPremiumThemes = () => {
    return (
      <>
        <div className={s.sectionHeader}>Pro Themes</div>
        <div className={s.allThemes}>
          <div className={s.themeElement}>
            <div
              className={s.themeSquare + " " + getDisabled()}
              onClick={() =>
                updateLocalAndDBTheme(
                  {
                    name: "clefairy",
                    primary:
                      "linear-gradient(90deg, #ffafbd 0%, #ffc3a0 35%, #ffc3a0 100%)",
                    secondary:
                      "linear-gradient(90deg, #ffafbd 0%, #ffc3a0 35%, #ffc3a0 100%)",
                    tertiary: "#fafafa",
                    bioColor: "#fafafa",
                    textColor: "#0d1f22",
                    textHover: "#fafafa",
                  },
                  true
                )
              }
            >
              <div className={s.clefairyTop}></div>
              <div className={s.clefairyBottom}></div>
            </div>
            <div className={s.themeName}>Clefairy</div>
            <a href="/clefairy" target="_blank">
              <div className={s.exampleThemeLink}>View an example </div>
            </a>
          </div>
          <div className={s.themeElement}>
            <div
              className={s.themeSquare + " " + getDisabled()}
              onClick={() => {
                updateLocalAndDBTheme(
                  {
                    name: "mulberry",
                    primary: "linear-gradient(90deg, #85FFBD 0%, #FFFB7D 100%)",
                    secondary:
                      "linear-gradient(90deg, #85FFBD 0%, #FFFB7D 100%)",
                    tertiary: "#fafafa",
                    bioColor: "#0d1f22",
                    textColor: "#0d1f22",
                    textHover: "#fafafa",
                  },
                  true
                );
              }}
            >
              <div className={s.mulberryTop}></div>
              <div className={s.mulberryBottom}></div>
            </div>
            <div className={s.themeName}>Mulberry</div>
            <a href="/clefairy" target="_blank">
              <div className={s.exampleThemeLink}>View an example </div>
            </a>
          </div>
        </div>
        <div>More coming soon!</div>
      </>
    );
  };

  const getRailClass = () => {
    if (useCustomTheme) {
      return s.rail;
    }
    return s.railNo;
  };

  const getSliderClass = () => {
    if (useCustomTheme) {
      return s.sliderSelected;
    }
    return s.slider;
  };

  const getRailClassHide = () => {
    if (hideCampah) {
      return s.rail;
    }
    return s.railNo;
  };

  const getSliderClassHide = () => {
    if (hideCampah) {
      return s.sliderSelected;
    }
    return s.slider;
  };

  const wrapSetCustomTheme = () => {
    if (accountType !== 1) {
      return;
    }
    setDBUseCustomTheme(!useCustomTheme);
    setUseCustomTheme(!useCustomTheme);
  };

  const wrapSetHideCampah = () => {
    if (accountType !== 1) {
      return;
    }
    setDBHideCampah(!hideCampah);
    setHideCampah(!hideCampah);
  };

  const renderCustomTheme = () => {
    return (
      <div className={s.customThemeHolder}>
        <div className={s.sectionHeader}>Custom Theme</div>
        <div className={s.customThemeRow}>
          <div className={s.useCustomThemeLabel}>Use custom Theme?</div>
          <div
            onClick={() => {
              wrapSetCustomTheme();
            }}
            className={getRailClass() + " " + getDisabled()}
          >
            <div className={getSliderClass() + " " + getDisabled()}></div>
          </div>
        </div>
        <div className={s.customThemeRowColor}>
          <div className={s.colorLabel}>Primary Color</div>
          <input
            className={s.colorInput}
            placeholder="#FF33FF"
            value={customTheme.primary}
            onChange={(e) => {
              setCustomTheme({ ...customTheme, primary: e.target.value });
            }}
            onBlur={() => updateCampsiteCustomTheme()}
            disabled={accountType !== 1}
          ></input>
          <div
            className={s.previewColor}
            style={{ background: customTheme.primary }}
          ></div>
        </div>
        <div className={s.customThemeRowColor}>
          <div className={s.colorLabel}>Secondary Color</div>
          <input
            className={s.colorInput}
            placeholder="#FF33FF"
            value={customTheme.secondary}
            onChange={(e) => {
              setCustomTheme({ ...customTheme, secondary: e.target.value });
            }}
            onBlur={() => updateCampsiteCustomTheme()}
            disabled={accountType !== 1}
          ></input>
          <div
            className={s.previewColor}
            style={{ background: customTheme.secondary }}
          ></div>
        </div>
        <div className={s.customThemeRowColor}>
          <div className={s.colorLabel}>Tertiary Color</div>
          <input
            className={s.colorInput}
            placeholder="#FF33FF"
            value={customTheme.tertiary}
            onChange={(e) => {
              setCustomTheme({ ...customTheme, tertiary: e.target.value });
            }}
            onBlur={() => updateCampsiteCustomTheme()}
            disabled={accountType !== 1}
          ></input>
          <div
            className={s.previewColor}
            style={{ background: customTheme.tertiary }}
          ></div>
        </div>
        <div className={s.customThemeRowColor}>
          <div className={s.colorLabel}>Bio Color</div>
          <input
            className={s.colorInput}
            placeholder="#FF33FF"
            value={customTheme.bioColor}
            onChange={(e) => {
              setCustomTheme({ ...customTheme, bioColor: e.target.value });
            }}
            onBlur={() => updateCampsiteCustomTheme()}
            disabled={accountType !== 1}
          ></input>
          <div
            className={s.previewColor}
            style={{ background: customTheme.bioColor }}
          ></div>
        </div>
        <div className={s.customThemeRowColor}>
          <div className={s.colorLabel}>Link Text Color</div>
          <input
            className={s.colorInput}
            placeholder="#FF33FF"
            value={customTheme.textColor}
            onChange={(e) => {
              setCustomTheme({ ...customTheme, textColor: e.target.value });
            }}
            onBlur={() => updateCampsiteCustomTheme()}
            disabled={accountType !== 1}
          ></input>
          <div
            className={s.previewColor}
            style={{ background: customTheme.textColor }}
          ></div>
        </div>
        <div className={s.customThemeRowColor}>
          <div className={s.colorLabel}>Link Hover Color</div>
          <input
            className={s.colorInput}
            placeholder="#FF33FF"
            value={customTheme.textHover}
            onChange={(e) => {
              setCustomTheme({ ...customTheme, textHover: e.target.value });
            }}
            onBlur={() => updateCampsiteCustomTheme()}
            disabled={accountType !== 1}
          ></input>
          <div
            className={s.previewColor}
            style={{ background: customTheme.textHover }}
          ></div>
        </div>
      </div>
    );
  };

  const renderHideCampah = () => {
    return (
      <div className={s.hideCampahSection}>
        <div className={s.sectionHeader}>Campah Logo</div>
        <div className={s.customThemeRow}>
          <div className={s.useCustomThemeLabel}>Hide campah logo?</div>
          <div
            onClick={() => {
              wrapSetHideCampah();
            }}
            className={getRailClassHide() + " " + getDisabled()}
          >
            <div className={getSliderClassHide() + " " + getDisabled()}></div>
          </div>
        </div>
      </div>
    );
  };

  const renderControls = () => {
    return (
      <div className={s.scrollingContainer}>
        <div className={s.formContainer}>
          <div className={s.tabsContainer}>
            <div>
              <div
                className={s.tabName}
                onClick={() => history.push("/dashboard")}
              >
                Content
              </div>
              <div className={s.redLine}></div>
            </div>
            <div>
              <div
                className={s.tabName}
                onClick={() => history.push("/dashboard/theme")}
              >
                Theme
              </div>
              <div className={s.redLineVisible}></div>
            </div>
            <div>
              <div
                className={s.tabName}
                onClick={() => history.push("/dashboard/account")}
              >
                My Account
              </div>
              <div className={s.redLine}></div>
            </div>
          </div>
          <div className={s.sectionHeader}>Default Themes</div>
          <div className={s.allThemes}>
            <div className={s.themeElement}>
              <div
                className={s.themeSquare}
                onClick={() => {
                  updateLocalAndDBTheme({
                    name: "campah",
                    primary: "#fafafa",
                    secondary: "#ff4242",
                    tertiary: "#ff4242",
                    bioColor: "#0d1f22",
                    textColor: "#fafafa",
                    textHover: "#fafafa",
                  });
                }}
              >
                <div className={s.campahTop}></div>
                <div className={s.campahBottom}></div>
              </div>
              <div className={s.themeName}>Campah</div>
            </div>
            <div className={s.themeElement}>
              <div
                className={s.themeSquare}
                onClick={() =>
                  updateLocalAndDBTheme({
                    name: "goomy",
                    primary: "#7678ed",
                    secondary: "#53dd6c",
                    tertiary: "#fafafa",
                    bioColor: "#fafafa",
                    textColor: "#0d1f22",
                    textHover: "#fafafa",
                  })
                }
              >
                <div className={s.gloomyTop}></div>
                <div className={s.gloomyBottom}></div>
              </div>
              <div className={s.themeName}>Goomy</div>
            </div>
          </div>
          {accountType !== 1 ? (
            <div className={s.upgradeContainer}>
              Pro Themes, Custom Themes, and Hiding the campah logo are
              available in the Pro Plan
              <a href="/pricing">
                <div className={s.upgradeButton}>Upgrade</div>
              </a>
            </div>
          ) : null}
          {renderPremiumThemes()}
          {renderCustomTheme()}
          {renderHideCampah()}
        </div>
      </div>
    );
  };

  const getDesktopClassName = () => {
    if (previewSize === "desktop") {
      return s.previewSizeSelectorButtonSelected;
    } else return s.previewSizeSelectorButton;
  };

  const getMobileClassName = () => {
    if (previewSize === "mobile") {
      return s.previewSizeSelectorButtonSelected;
    } else return s.previewSizeSelectorButton;
  };

  const getContainerClassName = () => {
    if (previewSize === "desktop") {
      return s.userPageContainerdesktop;
    } else return s.userPageContainermobile;
  };

  const renderHelmet = () => {
    return (
      <Helmet>
        <title>Campah – A Space for Everything You Create</title>
        <meta
          name="description"
          content="Content creation happens in a lot of different places, Campah helps you tie it together.  Claim a URL, set up a page in a minute and share your page wherever you want to."
        />
        <meta
          name="keywords"
          content="link in bio, page builder, space for everything you create"
        />
        <meta property="og:url" content="https://www.campah.com/" />
        <meta
          property="og:title"
          content="Campah – A Space for Everything You Create"
        />
        <meta
          property="og:description"
          content="Content creation happens in a lot of different places, Campah helps you tie it together.  Claim a URL, set up a page in a minute and share your page wherever you want to."
        />
        <meta property="og:image" content="https://i.imgur.com/Xb3LCcz.jpeg" />
        <meta
          property="og:image:url"
          content="https://i.imgur.com/Xb3LCcz.jpeg"
        />
        <meta
          property="twitter:image"
          content="https://i.imgur.com/Xb3LCcz.jpeg"
        />
      </Helmet>
    );
  };

  const renderPreview = () => {
    return (
      <div className={s.userPagePreview}>
        <div className={s.previewTopRow}>
          <div className={s.previewSizeSelector}>
            <div
              className={getDesktopClassName()}
              onClick={() => setPreviewSize("desktop")}
            >
              Desktop
            </div>
            <div
              className={getMobileClassName()}
              onClick={() => setPreviewSize("mobile")}
            >
              Mobile
            </div>
          </div>
        </div>

        <div className={getContainerClassName()}>
          <div className={s["conditionalSize" + previewSize]}>
            {siteName && (
              <UserPage
                source="dashboard"
                siteName={siteName}
                bioHeader={bioHeader}
                bioDescription={bioDescription}
                social={socialSection}
                profileImageLink={profileImageLink}
                links={linkArray}
                previewSize={previewSize}
                noEntryYet={noEntryYet()}
                theme={useCustomTheme ? customTheme : theme}
                hideCampah={hideCampah}
              />
            )}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      {noEntryYet()}
      {renderHelmet()}
      <NavBar></NavBar>
      <div className={s.pageContainer}>
        <div className={s.splitLine}></div>
        <div className={s.split}>
          {renderControls()}
          {renderPreview()}
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
