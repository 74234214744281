import React, { FunctionComponent, useState, useEffect } from "react";
import { useSelector } from "react-redux";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { useParams } from "react-router-dom";
import UserPage from "./UserPageDashboard";

const Dashboard: FunctionComponent<{ props: any }> = (props) => {
  const { siteName } = useParams();
  const user = useSelector((state: any) => state.user);

  useEffect(() => {
    let siteNameParam = siteName;
    if (siteNameParam) {
      siteNameParam = siteNameParam.toLowerCase();
    }

    fetch(`/api/getPublicCampsiteInfo/${siteNameParam}`)
      .then((response) => response.json())
      .then((data) => {
        if ("campsite" in data) {
          setBioHeader(data.campsite.bioHeader);
          setBioDescription(data.campsite.bioDescription);
          setProfileImageLink(data.campsite.profileImageLink);
          setSocialSection(data.campsite.social);
          setLinkArray(data.campsite.links);
          setTheme(data.campsite.theme);
          setUseCustomTheme(data.campsite.useCustomTheme);
          setCustomTheme(data.campsite.customTheme);
          setHideCampah(data.campsite.hideCampah);
          setAccountType(data.campsite.accountType);
        } else {
          setUnclaimed(true);
        }
      });
  }, [user.token, siteName]);

  // BEGIN States
  const [unclaimed, setUnclaimed] = useState(false);

  const [socialSection, setSocialSection] = useState({
    twitter: "",
    instagram: "",
    youtube: "",
    linkedin: "",
  });
  const [bioHeader, setBioHeader] = useState("");
  const [bioDescription, setBioDescription] = useState("");
  const [profileImageLink, setProfileImageLink] = useState("");
  // we need this extra piece of state for when we update link thumbnails
  const [theme, setTheme] = useState({
    name: "goomy",
    primary: "white",
    secondary: "#fafafa",
    tertiary: "#fafafa",
    bioColor: "#fafafa",
    textColor: "#fafafa",
    textHover: "#fafafa",
  });

  const [customTheme, setCustomTheme] = useState({
    primary: "",
    secondary: "",
    tertiary: "",
    bioColor: "",
    textColor: "",
    textHover: "",
  });
  const [linkArray, setLinkArray] = useState([
    { url: "", title: "", description: "", id: "", thumbnailLink: "" },
  ]);

  const [useCustomTheme, setUseCustomTheme] = useState(false);
  const [hideCampah, setHideCampah] = useState(false);
  const [accountType, setAccountType] = useState(false);

  // START render functions

  const renderPreview = () => {
    return (
      <UserPage
        source="dashboard"
        bioHeader={bioHeader}
        bioDescription={bioDescription}
        social={socialSection}
        profileImageLink={profileImageLink}
        links={linkArray}
        previewSize={"desktop"}
        noEntryYet={false}
        theme={useCustomTheme ? customTheme : theme}
        hideCampah={hideCampah}
        unclaimed={unclaimed}
        accountType={accountType}
      />
    );
  };

  return <div>{renderPreview()}</div>;
};

export default Dashboard;
