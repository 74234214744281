import React from "react";
import { Provider } from "react-redux";
import { Router, Route, Redirect, Switch } from "react-router-dom";
import Missing from "./components/Missing";
import { createBrowserHistory } from "history";
import { StripeProvider } from "react-stripe-elements";
import ReactGA from "react-ga";
import Toast from "./Toast";
import Home from "./components/core/Home";
import UserPage from "./components/core/UserPageRoot";
import SiteNameSelection from "./components/core/SiteNameSelection";
import Dashboard from "./components/core/Dashboard";
import Theme from "./components/core/Theme";
import MyAccount from "./components/core/MyAccount";
import Pricing from "./components/core/Pricing";
import Upgrade from "./components/core/Upgrade";
import ScrollToTop from "./ScrollToTop";

let history = undefined;
let stripePublicKey =
  "pk_live_51IV7wmDXgppcW2FHOKkoeN8HyAPuENQCO1RunJ5L1THjnmr9ryLP7RRrBr1wP00TwyltYrQAl4hWxVXTXUoexQLC00ZzF839a6";

if (typeof window !== "undefined" && window.location) {
  if (window.location.host === "campah.com") {
    stripePublicKey =
      "pk_live_51IV7wmDXgppcW2FHOKkoeN8HyAPuENQCO1RunJ5L1THjnmr9ryLP7RRrBr1wP00TwyltYrQAl4hWxVXTXUoexQLC00ZzF839a6";
  }
}

if (typeof window !== "undefined") {
  history = createBrowserHistory();

  history.listen((location, action) => {
    ReactGA.pageview(location.pathname);
  });
}

const Root = ({ store }) => (
  <StripeProvider apiKey={stripePublicKey}>
    <Provider store={store}>
      <Toast></Toast>
      <Router history={history}>
        <ScrollToTop />
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/pricing" component={Pricing} />
          <Route exact path="/dashboard" component={Dashboard} />
          <Route exact path="/dashboard/theme" component={Theme} />
          <Route exact path="/dashboard/account" component={MyAccount} />
          <Route exact path="/upgrade/:period" component={Upgrade} />
          <Route exact path="/start" component={SiteNameSelection} />
          <Route exact path="/:siteName" component={UserPage} />
          <Route exact path="/404" component={Missing} />
          <Redirect to="/404"></Redirect>
        </Switch>
      </Router>
    </Provider>
  </StripeProvider>
);

export default Root;
