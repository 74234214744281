import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { GoogleLogin } from "react-google-login";
import { bindActionCreators } from "redux";
import { setToken, setUser } from "../actions/user";
import { connect } from "react-redux";
import styles from "./GoogleSignIn.module.scss";

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ setToken, setUser }, dispatch);

class GoogleSignIn extends Component {
  setUserData = (data) => {
    const { setUser } = this.props;
    setUser(data);
  };

  checkWithOurServers = (token) => {
    const { setToken, successRoute, history } = this.props;

    fetch("/api/googleSignin", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ id_token: token }),
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setToken(data.token);
        this.setUserData(data);
        if (successRoute) {
          history.push(this.props.successRoute);
        }
      });
  };

  responseGoogle = (response) => {
    let token = response.tokenId;

    this.checkWithOurServers(token);
  };

  renderSignIn() {
    return (
      <GoogleLogin
        clientId="119535437376-iq7omvkeqi0b4pls738s1glj716bgpvn.apps.googleusercontent.com"
        buttonText={this.props.label || "Login"}
        onSuccess={this.responseGoogle}
        render={(renderProps) => (
          <div
            className={styles.googleButtonExpert}
            onClick={renderProps.onClick}
            disabled={renderProps.disabled}
          >
            <svg viewBox="0 0 15 15" className={styles.googleLogoExpert}>
              <path d="M 7.28571 6.4125L 7.28571 9L 11.3929 9C 11.2143 10.0875 10.1429 12.225 7.28571 12.225C 4.78571 12.225 2.78571 10.0875 2.78571 7.5C 2.78571 4.9125 4.82143 2.775 7.28571 2.775C 8.71429 2.775 9.64286 3.4125 10.1786 3.9375L 12.1429 1.9875C 10.8929 0.75 9.25 0 7.28571 0C 3.25 0 0 3.3375 0 7.5C 0 11.6625 3.25 15 7.28571 15C 11.5 15 14.25 11.9625 14.25 7.6875C 14.25 7.2 14.2143 6.825 14.1429 6.45L 7.28571 6.45L 7.28571 6.4125Z"></path>
            </svg>{" "}
            Continue with Google
          </div>
        )}
        onFailure={this.responseGoogle}
        cookiePolicy={"single_host_origin"}
      />
    );
  }

  renderNavSignIn() {
    return (
      <GoogleLogin
        clientId="119535437376-iq7omvkeqi0b4pls738s1glj716bgpvn.apps.googleusercontent.com"
        buttonText={this.props.label || "Login"}
        onSuccess={this.responseGoogle}
        render={(renderProps) => (
          <div
            className={styles.googleButtonNav}
            onClick={renderProps.onClick}
            disabled={renderProps.disabled}
          >
            Sign Up
          </div>
        )}
        onFailure={this.responseGoogle}
        cookiePolicy={"single_host_origin"}
      />
    );
  }

  render() {
    return (
      <div>
        <div>
          {this.props.navbar ? this.renderNavSignIn() : this.renderSignIn()}
        </div>
      </div>
    );
  }
}
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(GoogleSignIn)
);
