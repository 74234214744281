import React, { FunctionComponent } from "react";
import s from "./Footer.module.scss";

const Footer: FunctionComponent<{ props: any }> = (props) => {
  return (
    <div className={s.strip}>
      <div className={s.center}>campah 2021</div>
      <div className={s.center}>Made in NYC</div>
    </div>
  );
};

export default Footer;
