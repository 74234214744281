import React, { FunctionComponent, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { postWrapper, getWrapper } from "../../helpers/fetch";
import s from "./SiteNameSelection.module.scss";
import { Helmet } from "react-helmet";

const SiteNameSelection: FunctionComponent<{ props: any }> = (any) => {
  const user = useSelector((state: any) => state.user);
  const history = useHistory();

  const [siteName, setSiteName] = useState("");
  const [nameIsInvalid, setInvalidName] = useState(true);
  const [invalidMessage, setInvalidMessage] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getWrapper("GET", `/api/userHasCampsite`, {
      Authorization: user.token,
    }).then((userHasCampsiteResponse) => {
      if (
        "userHasCampsite" in userHasCampsiteResponse &&
        userHasCampsiteResponse.userHasCampsite === true
      ) {
        history.push("/dashboard");
      }
    });
  }, [user.token, history]);

  useEffect(() => {
    if (siteName === "" || siteName.length < 4) {
      setInvalidName(true);
      setInvalidMessage("");
    }
  }, [siteName]);

  const checkCampsite = async () => {
    if (siteName === "") return;
    const checkCampsiteResponse = await getWrapper(
      "GET",
      `/api/checkCampsite/${siteName}`,
      {
        Authorization: user.token,
      }
    );

    if (
      "found" in checkCampsiteResponse &&
      checkCampsiteResponse.found === true
    ) {
      setInvalidName(true);
      setInvalidMessage("URL already in use, please select another");
      return true;
    }
    return false;
  };

  const createCampsite = async () => {
    // if checkCampsite returns false, only then create a new one
    if (await checkCampsite()) {
      return;
    }

    setLoading(true);
    const createCampsiteResponse = await postWrapper(
      "POST",
      "/api/createCampsite",
      {
        Authorization: user.token,
        "Content-Type": "application/json",
      },
      JSON.stringify({ siteName })
    );

    if ("campsite" in createCampsiteResponse) {
      history.push("/dashboard");
    } else {
      setLoading(false);
      setInvalidMessage("Something went wrong on the server");
    }
  };

  const validateAndSetName = (e: any) => {
    // remove whitespace
    let cleanSiteName = e.target.value.replace(/\s+/g, "");

    // remove special characters
    cleanSiteName = cleanSiteName.replace(/[^a-z0-9]/gi, "");
    cleanSiteName = cleanSiteName.toLowerCase();

    setSiteName(cleanSiteName);
    resetInvalidMessage();
  };

  const resetInvalidMessage = () => {
    if (nameIsInvalid) {
      setInvalidName(false);
    }
  };

  const renderContinueButton = () => {
    let buttonText = "Get Started";
    let buttonClass = s.continue;

    if (loading) {
      buttonText = "Loading";
    }

    if (loading || nameIsInvalid) {
      buttonClass = s.continueInactive;
    }

    return (
      <div className={s.holdContinue}>
        <div
          className={buttonClass}
          onClick={
            loading || nameIsInvalid ? () => void 0 : () => createCampsite()
          }
        >
          {buttonText}
        </div>
      </div>
    );
  };

  const renderHelmet = () => {
    return (
      <Helmet>
        <title>Campah – A Space for Everything You Create</title>
        <meta
          name="description"
          content="Content creation happens in a lot of different places, Campah helps you tie it together.  Claim a URL, set up a page in a minute and share your page wherever you want to."
        />
        <meta
          name="keywords"
          content="link in bio, page builder, space for everything you create"
        />
        <meta property="og:url" content="https://www.campah.com/" />
        <meta
          property="og:title"
          content="Campah – A Space for Everything You Create"
        />
        <meta
          property="og:description"
          content="Content creation happens in a lot of different places, Campah helps you tie it together.  Claim a URL, set up a page in a minute and share your page wherever you want to."
        />
        <meta property="og:image" content="https://i.imgur.com/Xb3LCcz.jpeg" />
        <meta
          property="og:image:url"
          content="https://i.imgur.com/Xb3LCcz.jpeg"
        />
        <meta
          property="twitter:image"
          content="https://i.imgur.com/Xb3LCcz.jpeg"
        />
      </Helmet>
    );
  };

  return (
    <div>
      {renderHelmet()}
      <div className={s.pageHeader}>campah</div>
      <div className={s.formContainer}>
        <div className={s.formHeading}>Page Name</div>
        <div className={s.inputLeft}>
          campah.com/
          <input
            className={s.niceInput}
            maxLength={15}
            value={siteName}
            onChange={(e) => {
              validateAndSetName(e);
            }}
            onBlur={() => checkCampsite()}
          ></input>
        </div>
        {nameIsInvalid ? (
          <div className={s.invalidMessage}>{invalidMessage}</div>
        ) : null}
        <div className={s.formSubheading}>
          Sets the url that you will house your page and all other links
        </div>
        {renderContinueButton()}
      </div>
    </div>
  );
};

export default SiteNameSelection;
