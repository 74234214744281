import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import styles from "./Home.module.scss";
import { Helmet } from "react-helmet";
import { bindActionCreators } from "redux";
import { setUser, logOut } from "../../actions/user";
import { setCampsite } from "../../actions/campsites";
import { connect } from "react-redux";
import GoogleSignIn from "../GoogleSignIn";
import { getWrapper } from "../../helpers/fetch";
import { isEmpty } from "lodash";
import NavBar from "./NavBar";
import flying from "./flying.svg";
import Footer from "./Footer";

const mapStateToProps = (state) => ({
  user: state.user,
  fullState: state,
  campsites: state.campsites,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ setUser, logOut, setCampsite }, dispatch);

class Home extends Component {
  componentDidMount() {
    // lets refresh user on mount
    this.refreshUser();
  }

  async refreshUser() {
    const { setUser, user, setCampsite } = this.props;
    if (isEmpty(user)) return;
    const userInfo = await getWrapper("GET", "/api/refreshUser", {
      Authorization: user.token,
    });
    setUser(userInfo);
    if (userInfo.campsite) {
      setCampsite(userInfo.campsite);
    }
  }

  renderHelmet() {
    return (
      <Helmet>
        <title>Campah – A Space for Everything You Create</title>
        <meta
          name="description"
          content="Content creation happens in a lot of different places, Campah helps you tie it together.  Claim a URL, set up a page in a minute and share your page wherever you want to."
        />
        <meta
          name="keywords"
          content="link in bio, page builder, space for everything you create"
        />
        <meta property="og:url" content="https://www.campah.com/" />
        <meta
          property="og:title"
          content="Campah – A Space for Everything You Create"
        />
        <meta
          property="og:description"
          content="Content creation happens in a lot of different places, Campah helps you tie it together.  Claim a URL, set up a page in a minute and share your page wherever you want to."
        />
        <meta property="og:image" content="https://i.imgur.com/Xb3LCcz.jpeg" />
        <meta
          property="og:image:url"
          content="https://i.imgur.com/Xb3LCcz.jpeg"
        />
        <meta
          property="twitter:image"
          content="https://i.imgur.com/Xb3LCcz.jpeg"
        />
      </Helmet>
    );
  }

  logout() {
    this.props.logOut();
  }

  renderNavigationOptions() {
    const { campsites } = this.props;

    const campsiteSiteSet = !isEmpty(campsites);

    let buttonText = "Get Started";
    let route = "/start";

    if (campsiteSiteSet) {
      buttonText = "Go to Dashboard";
      route = "/dashboard";
    }

    return (
      <div
        className={styles.dashboardButton}
        onClick={() => this.props.history.push(route)}
      >
        {buttonText}
      </div>
    );
  }

  getSuccessRoute() {
    const { campsites } = this.props;
    const campsiteSiteSet = !isEmpty(campsites);

    let successRoute = campsiteSiteSet ? "/dashboard" : "/start";

    return successRoute;
  }

  renderSignInButton() {
    const { user } = this.props;

    return (
      <>
        {isEmpty(user) ? (
          <div className={styles.googleloginContainer}>
            <GoogleSignIn
              label="Get Started"
              expert={true}
              successRoute="/start"
            ></GoogleSignIn>
          </div>
        ) : (
          this.renderNavigationOptions()
        )}
      </>
    );
  }

  renderHome() {
    return (
      <div className={styles.fullContainer}>
        <div className={styles.contentContainer}>
          <div className={styles.flexSplash}>
            <div className={styles.lefty}>
              <div className={styles.splashHeader}>
                A Space For Everything You Create
              </div>
              <div className={styles.splashDescription}>
                Your content is in a lot of places, let's tie it together
              </div>
              {this.renderSignInButton()}
            </div>
            <div className={styles.imgContainer}>
              <img
                src={flying}
                alt="floatingMan"
                className={styles.splashImg}
              ></img>
            </div>
          </div>
        </div>
        <div className={styles.demoWrapper}>
          <div className={styles.demoContainer}>
            <div className={styles.demoTextHold}>
              <div className={styles.demoHeader}>Optimize for everywhere</div>
              <div className={styles.demoSubHeader}>
                Confidently showcase your content no matter what device your
                audience uses.
              </div>
            </div>

            <div className={styles.demoImageContainer}>
              <img
                className={styles.demoImage}
                alt="home screenshot"
                src="https://i.imgur.com/GxpiPwQ.jpg"
              ></img>
            </div>
          </div>
        </div>
        <div className={styles.demoWrapper}>
          <div className={styles.demoContainerReverse}>
            <div className={styles.demoTextHoldRight}>
              <div className={styles.demoHeader}>Live page in a minute</div>
              <div className={styles.demoSubHeader}>
                Sign in, claim a URL, and add links. No difficult configurations
                to slow you down.
              </div>
            </div>
            <div className={styles.demoImageContainer}>
              <img
                className={styles.demoImage}
                alt="home screenshot"
                src="https://i.imgur.com/K8AptOP.png"
              ></img>
            </div>
          </div>
        </div>
        <div className={styles.demoWrapper}>
          <div className={styles.demoContainer}>
            <div className={styles.demoTextHold}>
              <div className={styles.demoHeader}>Your page, your brand</div>
              <div className={styles.demoSubHeader}>
                Easily adjust the theme to tie to your brand. Point to your own
                url for more brand personalization.
              </div>
            </div>

            <div className={styles.demoImageContainer}>
              <img
                className={styles.demoImage}
                alt="home screenshot"
                src="https://i.imgur.com/1kpBqxt.jpg"
              ></img>
            </div>
          </div>
        </div>
        <div className={styles.empowerSection}>
          <div className={styles.sectionHeader}>
            Empowering and enabling the creator economy
          </div>
          <div className={styles.sectionSubHeader}>
            Your success is our success. Let us know how we can help your
            content convert more, become more impactful, or just tell us how
            you're feeling!
          </div>
        </div>
        <div className={styles.imgContainerEnd}>
          <img
            src="https://i.imgur.com/ku2DiEx.png"
            alt="susan"
            className={styles.splashImg}
          ></img>
        </div>{" "}
        <div className={styles.buttonContainerBottom}>
          {this.renderSignInButton()}
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className={styles.all}>
        {this.renderHelmet()}
        <NavBar></NavBar>
        {this.renderHome()}
        <Footer></Footer>
      </div>
    );
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Home));
