import React, { FunctionComponent, useState, useEffect } from "react";
import NavBar from "./NavBar";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { postWrapper, getWrapper } from "../../helpers/fetch";
import s from "./Dashboard.module.scss";
import { v4 as uuidv4 } from "uuid";
import { ReactSortable } from "react-sortablejs";
import { MdDragHandle, MdDelete, MdImage } from "react-icons/md";
import UserPage from "./UserPageDashboard";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { PickerOverlay } from "filestack-react";
import {
  FaTwitter,
  FaYoutube,
  FaLinkedinIn,
  FaInstagram,
} from "react-icons/fa";
import { Helmet } from "react-helmet";

const Dashboard: FunctionComponent<{ props: any }> = (props) => {
  const history = useHistory();
  const { pathname } = useLocation();
  const user = useSelector((state: any) => state.user);
  const dispatch = useDispatch();

  const accountType = useSelector((state: any) => state.campsites.accountType);

  type social = "twitter" | "instagram" | "youtube" | "linkedin";

  const [grabLinksAgainFlag, setGrabLinksAgainFlag] = useState("0");

  useEffect(() => {
    getWrapper("GET", `/api/getCampsiteInfo`, {
      Authorization: user.token,
    }).then((getCampsiteInfoResponse) => {
      const social = getCampsiteInfoResponse.campsite.social;
      dispatch({
        type: "SET_CAMPSITE",
        campsite: getCampsiteInfoResponse.campsite,
      });

      if ("campsite" in getCampsiteInfoResponse) {
        setSocialSection({ ...social });
        setSiteName(getCampsiteInfoResponse.campsite.siteName);
        setBioHeader(getCampsiteInfoResponse.campsite.bioHeader);
        setBioDescription(getCampsiteInfoResponse.campsite.bioDescription);
        setProfileImageLink(getCampsiteInfoResponse.campsite.profileImageLink);
        setLinkArray(getCampsiteInfoResponse.campsite.links);
        setTheme(getCampsiteInfoResponse.campsite.theme);
        setUseCustomTheme(getCampsiteInfoResponse.campsite.useCustomTheme);
        setCustomTheme(getCampsiteInfoResponse.campsite.customTheme);
        setHideCampah(getCampsiteInfoResponse.campsite.hideCampah);
      }
    });
  }, [user.token, grabLinksAgainFlag]);

  const setSocialLink = (platform: string, link: string) => {
    setSocialSection({ ...socialSection, [platform]: link });
  };

  // instead of making 3 diff setters, figure out how index signatures work properly
  const updateStateUrl = (el: any, ind: number, url: string) => {
    const copyLinkArray = [...linkArray];
    copyLinkArray[ind].url = url;
    setLinkArray(copyLinkArray);
  };

  const updateStateTitle = (el: any, ind: number, title: string) => {
    const copyLinkArray = [...linkArray];
    copyLinkArray[ind].title = title;
    setLinkArray(copyLinkArray);
  };

  const updateStateDescription = (
    el: any,
    ind: number,
    description: string
  ) => {
    const copyLinkArray = [...linkArray];
    copyLinkArray[ind].description = description;
    setLinkArray(copyLinkArray);
  };

  const updateStateThumbnailLink = (
    el: any,
    ind: number,
    thumbnailLink: string
  ) => {
    const copyLinkArray = [...linkArray];
    copyLinkArray[ind].thumbnailLink = thumbnailLink;
    setLinkArray(copyLinkArray);
  };

  // BEGIN States

  const [siteName, setSiteName] = useState("");
  const [socialSection, setSocialSection] = useState({
    twitter: "",
    instagram: "",
    youtube: "",
    linkedin: "",
  });
  const [previewSize, setPreviewSize] = useState("desktop");
  const [bioHeader, setBioHeader] = useState("");
  const [bioDescription, setBioDescription] = useState("");
  const [profileImageLink, setProfileImageLink] = useState("");
  const [showUploader, setShowUploader] = useState(false);
  const [uploadLocation, setUploadLocation] = useState("");
  // we need this extra piece of state for when we update link thumbnails
  const [uploadLocationIndex, setUploadLocationIndex] = useState(-1);
  const [linkArray, setLinkArray] = useState([
    { url: "", title: "", description: "", id: "", thumbnailLink: "" },
  ]);
  const [newLink, setNewLink] = useState({
    url: "",
    title: "",
    description: "",
    thumbnailLink: "",
    id: uuidv4(),
  });
  const [theme, setTheme] = useState({
    name: "goomy",
    primary: "black",
    secondary: "black",
    tertiary: "black",
    bioColor: "black",
    textColor: "black",
    textHover: "black",
  });
  const [useCustomTheme, setUseCustomTheme] = useState(false);
  const [hideCampah, setHideCampah] = useState(false);
  const [customTheme, setCustomTheme] = useState({
    primary: "",
    secondary: "",
    tertiary: "",
    bioColor: "",
    textColor: "",
    textHover: "",
  });

  // END states

  // START api calls
  const updateCampsiteSocial = async (platform: social) => {
    await postWrapper(
      "POST",
      "/api/updateSocial",
      {
        Authorization: user.token,
        "Content-Type": "application/json",
      },
      JSON.stringify({ platform, updatedValue: socialSection[platform] })
    );
  };

  const updateCampsiteBio = async () => {
    await postWrapper(
      "POST",
      "/api/updateBio",
      {
        Authorization: user.token,
        "Content-Type": "application/json",
      },
      JSON.stringify({ bioHeader, bioDescription })
    );
  };

  const updateCampsiteProfImage = async (profileImageLink: string) => {
    await postWrapper(
      "POST",
      "/api/updateProfImage",
      {
        Authorization: user.token,
        "Content-Type": "application/json",
      },
      JSON.stringify({ profileImageLink })
    );
  };

  const updateCampsiteLink = async (index: number, newLinkThumbnail = "") => {
    const copyLinkObj = linkArray[index];
    if (newLinkThumbnail !== "") {
      copyLinkObj.thumbnailLink = newLinkThumbnail;
    }
    await postWrapper(
      "POST",
      "/api/updateLink",
      {
        Authorization: user.token,
        "Content-Type": "application/json",
      },
      JSON.stringify({ linkObj: copyLinkObj })
    );
  };

  const deleteCampsiteLink = async (index: number) => {
    await postWrapper(
      "POST",
      "/api/deleteLink",
      {
        Authorization: user.token,
        "Content-Type": "application/json",
      },
      JSON.stringify({ linkObj: linkArray[index] })
    );
    setGrabLinksAgainFlag(uuidv4());
  };

  const submitNewLink = async () => {
    await postWrapper(
      "POST",
      "/api/addNewLink",
      {
        Authorization: user.token,
        "Content-Type": "application/json",
      },
      JSON.stringify({ newLink })
    );
    setGrabLinksAgainFlag(uuidv4());
    setNewLink({
      url: "",
      title: "",
      description: "",
      thumbnailLink: "",
      id: uuidv4(),
    });
  };

  const updateAllLinkArray = async () => {
    await postWrapper(
      "POST",
      "/api/updateAllLinkArray",
      {
        Authorization: user.token,
        "Content-Type": "application/json",
      },
      JSON.stringify({ linkArray })
    );
  };

  // END api calls

  const uploaderSuccess = async (res: any) => {
    if (res.filesUploaded.length > 0) {
      const uploadURL = res.filesUploaded[0].url;

      if (uploadLocation === "profileImage") {
        await updateCampsiteProfImage(uploadURL);
        setGrabLinksAgainFlag(uuidv4());
      } else if (uploadLocation === "newLinkThumbnail") {
        setNewLink({ ...newLink, thumbnailLink: uploadURL });
      } else if (uploadLocation === "existingLinkThumbnail") {
        updateStateThumbnailLink({}, uploadLocationIndex, uploadURL);
        await updateCampsiteLink(uploadLocationIndex, uploadURL);
      }
    }
    return;
  };

  const newLinkIsValid = () => {
    if (newLink.url !== "" && newLink.title !== "") {
      return true;
    }
    return false;
  };

  const noEntryYet = () => {
    if (
      !profileImageLink &&
      !bioHeader &&
      !bioDescription &&
      socialSection !==
        { twitter: "", instagram: "", youtube: "", linkedin: "" } &&
      !linkArray
    ) {
      return true;
    } else return false;
  };

  const gateNewLink = () => {
    if (linkArray && linkArray.length >= 10 && accountType !== 1) {
      return true;
    }
    return false;
  };

  // START render functions
  const renderHelmet = () => {
    return (
      <Helmet>
        <title>Campah – A Space for Everything You Create</title>
        <meta
          name="description"
          content="Content creation happens in a lot of different places, Campah helps you tie it together.  Claim a URL, set up a page in a minute and share your page wherever you want to."
        />
        <meta
          name="keywords"
          content="link in bio, page builder, space for everything you create"
        />
        <meta property="og:url" content="https://www.campah.com/" />
        <meta
          property="og:title"
          content="Campah – A Space for Everything You Create"
        />
        <meta
          property="og:description"
          content="Content creation happens in a lot of different places, Campah helps you tie it together.  Claim a URL, set up a page in a minute and share your page wherever you want to."
        />
        <meta property="og:image" content="https://i.imgur.com/Xb3LCcz.jpeg" />
        <meta
          property="og:image:url"
          content="https://i.imgur.com/Xb3LCcz.jpeg"
        />
        <meta
          property="twitter:image"
          content="https://i.imgur.com/Xb3LCcz.jpeg"
        />
      </Helmet>
    );
  };

  const renderSocialSection = () => {
    return (
      <div>
        <div className={s.sectionHeader}> Social Badges</div>
        <div className={s.badgesContainer}>
          <div className={s.platformContainer}>
            <div className={s.socialIconContainer}>
              <FaTwitter size="18px" />
            </div>
            <div className={s.socialLinkInputContainer}>
              <input
                className={s.linkInput}
                placeholder="twitter.com/shahgaleeb"
                value={socialSection.twitter}
                onChange={(e) => {
                  setSocialLink("twitter", e.target.value);
                }}
                onBlur={() => updateCampsiteSocial("twitter")}
              ></input>
            </div>
          </div>
          <div className={s.platformContainer}>
            <div className={s.socialIconContainer}>
              <FaInstagram size="18px" />
            </div>
            <div className={s.socialLinkInputContainer}>
              <input
                className={s.linkInput}
                placeholder="instagram.com/shahahmed/"
                value={socialSection.instagram}
                onChange={(e) => {
                  setSocialLink("instagram", e.target.value);
                }}
                onBlur={() => updateCampsiteSocial("instagram")}
              ></input>
            </div>
          </div>
          <div className={s.platformContainer}>
            <div className={s.socialIconContainer}>
              <FaYoutube size="18px" />
            </div>
            <div className={s.socialLinkInputContainer}>
              <input
                className={s.linkInput}
                placeholder="youtube.com/channel/UC4yLof30hto1bzGYFJkTO7g"
                value={socialSection.youtube}
                onChange={(e) => {
                  setSocialLink("youtube", e.target.value);
                }}
                onBlur={() => updateCampsiteSocial("youtube")}
              ></input>
            </div>
          </div>
          <div className={s.platformContainer}>
            <div className={s.socialIconContainer}>
              <FaLinkedinIn size="18px" />
            </div>
            <div className={s.socialLinkInputContainer}>
              <input
                className={s.linkInput}
                placeholder="linkedin.com/in/shah-ahmed-1099a934/"
                value={socialSection.linkedin}
                onChange={(e) => {
                  setSocialLink("linkedin", e.target.value);
                }}
                onBlur={() => updateCampsiteSocial("linkedin")}
              ></input>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderBioSection = () => {
    return (
      <div className={s.linkSection}>
        <div className={s.sectionHeader}> Bio </div>
        <div className={s.newLinkEntry}>
          <div className={s.linkInputContainer}>
            <input
              className={s.linkInput}
              placeholder='Header:" Hi, my name is Shah!!" '
              value={bioHeader}
              onChange={(e) => {
                setBioHeader(e.target.value);
              }}
              onBlur={() => updateCampsiteBio()}
            ></input>
          </div>

          <div className={s.linkInputContainer}>
            <textarea
              className={s.simpleArea}
              placeholder="Description: I'm a product thinker and creator. I'm the first Product Manager at Kapwing - an online video editor. I also create my own projects at FailFlow. I love building and I love helping people and ... "
              value={bioDescription}
              onChange={(e) => {
                setBioDescription(e.target.value);
              }}
              onBlur={() => updateCampsiteBio()}
            ></textarea>
          </div>
        </div>
      </div>
    );
  };

  const renderLinkSectionSortable = () => {
    if (linkArray) {
      return (
        <>
          <div className={s.sectionHeader}> Link Cards</div>
          <div className={s.existingLinksContainer}>
            <ReactSortable
              animation={200}
              delay={10}
              list={linkArray}
              setList={setLinkArray}
              onEnd={() => {
                updateAllLinkArray();
              }}
              handle={"." + s.poopHandle}
              ghostClass={"hideBaby"}
            >
              {linkArray.map((el, ind) => (
                <div className={s.existingLink} key={el.id}>
                  <div className={s.leftSide}>
                    <div className={s.linkInputContainer}>
                      <input
                        className={s.linkInput}
                        placeholder="URL: https://www.myportfolio.com"
                        value={el.url}
                        onChange={(e) => {
                          updateStateUrl(el, ind, e.target.value);
                        }}
                        onBlur={() => updateCampsiteLink(ind)}
                      ></input>
                    </div>
                    <div className={s.linkInputContainer}>
                      <input
                        className={s.linkInput}
                        placeholder="Title: Design Portfolios"
                        value={el.title}
                        onChange={(e) => {
                          updateStateTitle(el, ind, e.target.value);
                        }}
                        onBlur={() => updateCampsiteLink(ind)}
                      ></input>
                    </div>
                    <div className={s.linkInputContainer}>
                      <textarea
                        className={s.simpleArea}
                        placeholder="Description (optional): My college design portfolio from ... "
                        value={el.description}
                        onChange={(e) => {
                          updateStateDescription(el, ind, e.target.value);
                        }}
                        onBlur={() => updateCampsiteLink(ind)}
                      ></textarea>
                      <div className={s.linkInputContainer}>
                        <input
                          className={s.linkInput}
                          placeholder="Thumbnail Image link "
                          value={el.thumbnailLink}
                          onChange={(e) => {
                            updateStateThumbnailLink(el, ind, e.target.value);
                          }}
                          onBlur={() => updateCampsiteLink(ind)}
                        ></input>
                      </div>
                    </div>
                  </div>
                  <div className={s.rightSide}>
                    <div className={s.poopHandle}>
                      <MdDragHandle size="24px" />
                    </div>
                    <div
                      className={s.trashIcon}
                      onClick={() => deleteCampsiteLink(ind)}
                    >
                      <MdDelete size="24px" />{" "}
                    </div>
                    <div
                      className={s.trashIcon}
                      onClick={() => {
                        setUploadLocation("existingLinkThumbnail");
                        setUploadLocationIndex(ind);
                        setShowUploader(true);
                      }}
                    >
                      <MdImage size="24px" />
                    </div>
                    {el.thumbnailLink && (
                      <img
                        className={s.linkThumbnailPreview}
                        src={el.thumbnailLink}
                        alt={el.thumbnailLink}
                      ></img>
                    )}
                  </div>
                </div>
              ))}
            </ReactSortable>
          </div>
        </>
      );
    }
  };

  const renderGatedNewLink = () => {
    return (
      <>
        <div className={s.sectionHeader}> New Link</div>
        <div className={s.upgradeContainer}>
          Upgrade to Pro in order to add unlimited link cards
          <a href="/pricing">
            <div className={s.upgradeButton}>Upgrade</div>
          </a>
        </div>
        <div className={s.spaceUnder}></div>
      </>
    );
  };

  const renderNewLinkSection = () => {
    return (
      <>
        <div className={s.sectionHeader}> New Link</div>
        <div className={s.newLinkEntry}>
          <div className={s.linkInputContainer}>
            <input
              className={s.linkInput}
              placeholder="URL: https://www.myportfolio.com"
              value={newLink.url}
              onChange={(e) => {
                setNewLink({ ...newLink, url: e.target.value });
              }}
            ></input>
          </div>
          <div className={s.linkInputContainer}>
            <input
              className={s.linkInput}
              placeholder="Title: Design Portfolio"
              value={newLink.title}
              onChange={(e) => {
                setNewLink({ ...newLink, title: e.target.value });
              }}
            ></input>
          </div>
          <div className={s.linkInputContainer}>
            <textarea
              className={s.simpleArea}
              placeholder="Description (optional): My college design portfolio from ... "
              value={newLink.description}
              onChange={(e) => {
                setNewLink({ ...newLink, description: e.target.value });
              }}
            ></textarea>
          </div>
          {/* <div className={s.helpText}>
            Paste a link to an image to add a thumbnail for your link, we
            recommend using imgur
          </div> */}
          <div className={s.linkInputContainer}>
            <div
              className={s.uploadImageNewLink}
              onClick={() => {
                setUploadLocation("newLinkThumbnail");
                setShowUploader(true);
              }}
            >
              {newLink.thumbnailLink ? "Change Image" : "Upload Image"}
            </div>
            {/* <input
              className={s.linkInput}
              placeholder="Thumbnail Image link "
              value={newLink.thumbnailLink}
              onChange={(e) => {
                setNewLink({ ...newLink, thumbnailLink: e.target.value });
              }}
            ></input> */}
            {newLink.thumbnailLink ? (
              <div className={s.newLinkImagePreviewContainer}>
                <img
                  className={s.newLinkImagePreview}
                  src={newLink.thumbnailLink}
                  alt="new link thumbnail"
                ></img>
              </div>
            ) : null}
          </div>
          <div className={s.holdSave}>
            {newLinkIsValid() ? (
              <div className={s.linkSaveButton} onClick={() => submitNewLink()}>
                Add Link
              </div>
            ) : (
              <div className={s.linkSaveButtonDisabled}>Add Link</div>
            )}
          </div>
        </div>
        <div className={s.spacer}></div>
      </>
    );
  };

  const shit = () => {
    setShowUploader(false);
  };

  const getTabClassName = (url: string) => {
    if (url === pathname) {
      return s.redLineVisible;
    }
    return s.redLineHidden;
  };

  const renderControls = () => {
    return (
      <div className={s.scrollingContainer}>
        <div className={s.formContainer}>
          {showUploader && (
            <PickerOverlay
              apikey={"AQ1HKDcINSQSM6V0sr17uz"}
              onSuccess={(res: any) => {
                uploaderSuccess(res);
              }}
              pickerOptions={{
                transformations: {
                  crop: {
                    aspectRatio: 1 / 1,
                    force: true,
                  },
                  circle: false,
                },
                onClose: shit,
              }}
            />
          )}

          <div className={s.tabsContainer}>
            <div onClick={() => history.push("/dashboard")}>
              <div className={s.tabName}>Content</div>
              <div className={getTabClassName("/dashboard")}></div>
            </div>
            <div onClick={() => history.push("/dashboard/theme")}>
              <div className={s.tabName}>Theme</div>
              <div className={s.redLine}></div>
            </div>
            <div onClick={() => history.push("/dashboard/account")}>
              <div className={s.tabName}>My Account</div>
              <div className={s.redLine}></div>
            </div>
          </div>
          <div className={s.rightFloat}>
            <div className={s.yourPageButton}>
              <a href={`/${siteName}`} target="_blank" rel="noreferrer">
                View Your Page{" "}
              </a>
            </div>
          </div>
          <div className={s.linkSection}>
            <div className={s.sectionHeader}> Profile Image</div>

            <div className={s.profImageEntryWrapper}>
              <div className={s.profImageEntryContainer}>
                <div className={s.profImageRow}>
                  <div>
                    <div className={s.profImageContainer}>
                      {profileImageLink ? (
                        <img
                          className={s.profImagePreview}
                          src={profileImageLink}
                          alt="profImage Preview"
                        ></img>
                      ) : (
                        <img
                          className={s.profImagePreview}
                          src="https://i.imgur.com/MOs86AB.jpeg"
                          alt="profImage Preview"
                        ></img>
                      )}
                    </div>

                    <div
                      className={s.uploadButton}
                      onClick={() => {
                        setUploadLocation("profileImage");
                        setShowUploader(true);
                      }}
                    >
                      Upload Image
                    </div>
                  </div>
                </div>
                {/* <div className={s.helpText}>
                  Keeping this link entry just for dev, final experience will
                  only be just the upload
                </div>
                <input
                  className={s.linkInput}
                  placeholder="imgur.com/sdjfoiejrf"
                  value={profileImageLink}
                  onChange={(e) => {
                    setProfileImageLink(e.target.value);
                  }}
                  onBlur={() => updateCampsiteProfImage(profileImageLink)}
                ></input> */}
              </div>
            </div>
          </div>
          {renderSocialSection()}
          {renderBioSection()}
          <div className={s.linkSection}>
            {renderLinkSectionSortable()}
            {gateNewLink() ? renderGatedNewLink() : renderNewLinkSection()}
          </div>
        </div>
      </div>
    );
  };

  const getDesktopClassName = () => {
    if (previewSize === "desktop") {
      return s.previewSizeSelectorButtonSelected;
    } else return s.previewSizeSelectorButton;
  };

  const getMobileClassName = () => {
    if (previewSize === "mobile") {
      return s.previewSizeSelectorButtonSelected;
    } else return s.previewSizeSelectorButton;
  };

  const getContainerClassName = () => {
    if (previewSize === "desktop") {
      return s.userPageContainerdesktop;
    } else return s.userPageContainermobile;
  };

  const renderPreview = () => {
    return (
      <div className={s.userPagePreview}>
        <div className={s.previewTopRow}>
          <div className={s.previewSizeSelector}>
            <div
              className={getDesktopClassName()}
              onClick={() => setPreviewSize("desktop")}
            >
              Desktop
            </div>
            <div
              className={getMobileClassName()}
              onClick={() => setPreviewSize("mobile")}
            >
              Mobile
            </div>
          </div>
        </div>
        <a href="/pricing">
          {accountType !== 1 && (
            <div className={s.notProWarning}>
              Upgrade to Pro to Publish your page. Your URL will be saved.
            </div>
          )}
        </a>

        <div className={getContainerClassName()}>
          <div className={s["conditionalSize" + previewSize]}>
            {siteName && (
              <UserPage
                source="dashboard"
                siteName={siteName}
                bioHeader={bioHeader}
                bioDescription={bioDescription}
                social={socialSection}
                profileImageLink={profileImageLink}
                links={linkArray}
                previewSize={previewSize}
                noEntryYet={noEntryYet()}
                theme={useCustomTheme ? customTheme : theme}
                hideCampah={hideCampah}
              />
            )}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      {noEntryYet()}
      {renderHelmet()}
      <NavBar></NavBar>
      <div className={s.pageContainer}>
        <div className={s.splitLine}></div>
        <div className={s.split}>
          {renderControls()}
          {renderPreview()}
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
