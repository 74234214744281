import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import NavBar from "./NavBar";
import styles from "./Upgrade.module.scss";
import { Helmet } from "react-helmet";
import { Elements } from "react-stripe-elements";
import UpgradeStripeForm from "./UpgradeStripeForm";
import "./Upgrade.css";

class Upgrade extends Component {
  renderHelmet() {
    return (
      <Helmet>
        <title>Campah – A Space for Everything You Create</title>
        <meta
          name="description"
          content="Content creation happens in a lot of different places, Campah helps you tie it together.  Claim a URL, set up a page in a minute and share your page wherever you want to."
        />
        <meta
          name="keywords"
          content="link in bio, page builder, space for everything you create"
        />
        <meta property="og:url" content="https://www.campah.com/" />
        <meta
          property="og:title"
          content="Campah – A Space for Everything You Create"
        />
        <meta
          property="og:description"
          content="Content creation happens in a lot of different places, Campah helps you tie it together.  Claim a URL, set up a page in a minute and share your page wherever you want to."
        />
        <meta property="og:image" content="https://i.imgur.com/Xb3LCcz.jpeg" />
        <meta
          property="og:image:url"
          content="https://i.imgur.com/Xb3LCcz.jpeg"
        />
        <meta
          property="twitter:image"
          content="https://i.imgur.com/Xb3LCcz.jpeg"
        />
      </Helmet>
    );
  }

  getPeriod() {
    const period = this.props.match.params.period;
    if (period === "monthly") {
      return "Monthly";
    } else if (period === "annual") {
      return "Annually";
    }
    return "????";
  }

  getPrice() {
    const period = this.props.match.params.period;
    if (period === "monthly") {
      return "10";
    } else if (period === "annual") {
      return "84";
    } else if (period === "believer") {
      return "250";
    }
    return "????";
  }

  render() {
    return (
      <div>
        {this.renderHelmet()}
        <NavBar />
        <div className={styles.paymentContainer}>
          <div className={styles.header}>Checkout</div>
          <div className={styles.subHeader}>
            {this.props.match.params.period === "believer" ? (
              "You've selected the Believer plan, billed once at $250."
            ) : (
              <>
                You've selected the Pro plan, billed {this.getPeriod()} at $
                {this.getPrice()} per year.
              </>
            )}
          </div>
          <div className={styles.lineItemContainer}>
            <div className={styles.how}>
              <div className={styles.howHeader}>Campah Pro Plan</div>
              <div>
                After checking out, your account will be upgraded, and you can
                enjoy the benefits immediately!
              </div>
              <div className={styles.total}>Total: ${this.getPrice()} </div>
            </div>
          </div>
          <div>Enter your payment details:</div>
          <Elements>
            <UpgradeStripeForm period={this.props.match.params.period} />
          </Elements>
          <div className={styles.emptySpace}></div>
        </div>
      </div>
    );
  }
}

export default withRouter(Upgrade);
