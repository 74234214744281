import React from "react";
import { render } from "react-dom";
import "./index.css";
import configureStore from "./configureStore";
import Root from "./Root";
import ReactGA from "react-ga";

ReactGA.initialize("UA-192946570-1");
ReactGA.pageview(window.location.pathname + window.location.search);

export const store = configureStore();

render(<Root store={store} />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
