const campsites = (state = {}, action) => {
  switch (action.type) {
    case "SET_CAMPSITE":
      return { ...state, ...action.campsite };
    case "LOG_OUT":
      return {};
    default:
      return state;
  }
};

export default campsites;
