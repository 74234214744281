import React, { FunctionComponent, useState, useEffect } from "react";
import NavBar from "./NavBar";
import { Helmet } from "react-helmet";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { postWrapper, getWrapper } from "../../helpers/fetch";
import s from "./Pricing.module.scss";
import { v4 as uuidv4 } from "uuid";
import { isEmpty } from "lodash";
import { FaCheck } from "react-icons/fa";
import Footer from "./Footer";

const Pricing: FunctionComponent<{ props: any }> = (props) => {
  const history = useHistory();
  const { pathname } = useLocation();
  const user = useSelector((state: any) => state.user);
  const dispatch = useDispatch();

  type social = "twitter" | "instagram" | "youtube" | "linkedin";

  const [grabLinksAgainFlag, setGrabLinksAgainFlag] = useState("0");

  useEffect(() => {
    if (!isEmpty(user)) {
      getWrapper("GET", `/api/getCampsiteInfo`, {
        Authorization: user.token,
      }).then((getCampsiteInfoResponse) => {
        const social = getCampsiteInfoResponse.campsite.social;

        if ("campsite" in getCampsiteInfoResponse) {
          setSocialSection({ ...social });
          setSiteName(getCampsiteInfoResponse.campsite.siteName);
          setBioHeader(getCampsiteInfoResponse.campsite.bioHeader);
          setBioDescription(getCampsiteInfoResponse.campsite.bioDescription);
          setProfileImageLink(
            getCampsiteInfoResponse.campsite.profileImageLink
          );
          setLinkArray(getCampsiteInfoResponse.campsite.links);
          setTheme(getCampsiteInfoResponse.campsite.theme);
        }
      });
    }
  }, [user, grabLinksAgainFlag]);

  // BEGIN States

  const [siteName, setSiteName] = useState("");
  const [socialSection, setSocialSection] = useState({
    twitter: "",
    instagram: "",
    youtube: "",
    linkedin: "",
  });
  const [previewSize, setPreviewSize] = useState("desktop");
  const [bioHeader, setBioHeader] = useState("");
  const [bioDescription, setBioDescription] = useState("");
  const [profileImageLink, setProfileImageLink] = useState("");
  const [showUploader, setShowUploader] = useState(false);
  const [uploadLocation, setUploadLocation] = useState("");
  // we need this extra piece of state for when we update link thumbnails
  const [uploadLocationIndex, setUploadLocationIndex] = useState(-1);
  const [linkArray, setLinkArray] = useState([
    { url: "", title: "", description: "", id: "", thumbnailLink: "" },
  ]);
  const [newLink, setNewLink] = useState({
    url: "",
    title: "",
    description: "",
    thumbnailLink: "",
    id: uuidv4(),
  });
  const [theme, setTheme] = useState({
    name: "goomy",
    primary: "black",
    secondary: "black",
    tertiary: "black",
    bioColor: "black",
    textColor: "black",
    textHover: "black",
  });

  // pricing state
  const [subscriptionTerm, setSubscriptionTerm] = useState("annual");
  const [monthlyAmount, setMonthlyAmount] = useState("7");

  // END states

  // END api calls
  const getPillClass = (term: string) => {
    if (term === subscriptionTerm) {
      return s.selectedPill;
    } else return s.pill;
  };

  const renderHelmet = () => {
    return (
      <Helmet>
        <title>Campah – A Space for Everything You Create</title>
        <meta
          name="description"
          content="Content creation happens in a lot of different places, Campah helps you tie it together.  Claim a URL, set up a page in a minute and share your page wherever you want to."
        />
        <meta
          name="keywords"
          content="link in bio, page builder, space for everything you create"
        />
        <meta property="og:url" content="https://www.campah.com/" />
        <meta
          property="og:title"
          content="Campah – A Space for Everything You Create"
        />
        <meta
          property="og:description"
          content="Content creation happens in a lot of different places, Campah helps you tie it together.  Claim a URL, set up a page in a minute and share your page wherever you want to."
        />
        <meta property="og:image" content="https://i.imgur.com/Xb3LCcz.jpeg" />
        <meta
          property="og:image:url"
          content="https://i.imgur.com/Xb3LCcz.jpeg"
        />
        <meta
          property="twitter:image"
          content="https://i.imgur.com/Xb3LCcz.jpeg"
        />
      </Helmet>
    );
  };

  return (
    <div>
      {renderHelmet()}
      <NavBar></NavBar>
      <div className={s.pageContainer}>
        <div className={s.contentContainer}>
          <div className={s.preHeader}>Pricing</div>
          <div className={s.splashText}>
            A space for your professional brand.
          </div>
          <div className={s.splashText}>Free to get started. </div>
          <div className={s.rightRow}>
            <div className={s.alignRight}>
              <div
                onClick={() => {
                  setSubscriptionTerm("annual");
                  setMonthlyAmount("7");
                }}
                className={getPillClass("annual")}
              >
                Annual
              </div>
              <div
                onClick={() => {
                  setSubscriptionTerm("monthly");
                  setMonthlyAmount("10");
                }}
                className={getPillClass("monthly")}
              >
                Monthly
              </div>
            </div>
          </div>

          <div className={s.pricesContainer}>
            <div className={s.priceBlockPro}>
              <div className={s.priceName}>Pro</div>

              <div className={s.benefit}>
                <FaCheck className={s.check} />
                Premium and custom themes
              </div>
              <div className={s.benefit}>
                <FaCheck className={s.check} />
                Hide the campah logo on your page
              </div>
              <div className={s.benefit}>
                <FaCheck className={s.check} />
                <div>Personal campah URL </div>
              </div>

              <div className={s.benefit}>
                <FaCheck className={s.check} />
                <div>Add image thumbnail to link cards</div>
              </div>
              <div className={s.price}>
                <div className={s.amount}>${monthlyAmount}</div>
                <div className={s.underAmount}>a month</div>
              </div>
              <a href={"/upgrade/" + subscriptionTerm}>
                <div className={s.upgradeButton}>Upgrade</div>
              </a>
            </div>
            <div className={s.priceBlockFree}>
              <div className={s.priceName}>Enterprise</div>
              <div className={s.benefit}>
                <FaCheck className={s.check} />x<div>All of Pro included</div>
              </div>
              <div className={s.benefit}>
                <FaCheck className={s.check} />
                Use custom domains
              </div>
              <div className={s.benefit}>
                <FaCheck className={s.check} />
                Multiple account access
              </div>
              <div className={s.benefit}>
                <FaCheck className={s.check} />
                Optimized SEO, SSR, meta tags, and OG info
              </div>
              <div className={s.benefit}>
                <FaCheck className={s.check} />
                Priority support and account management
              </div>

              <a href={"mailto:shah@failflow.com"}>
                <div className={s.upgradeButton}>Contact</div>
              </a>
            </div>
          </div>

          <div className={s.believerHeader}>
            Believer in the creator economy?
          </div>
          <div className={s.believerDescription}>
            Our goal is to empower and enable creators, if you want to help us
            reach that goal, join us on the journey!
          </div>

          <div className={s.believerContainer}>
            <div className={s.priceName}>Believer</div>
            <div className={s.benefit}>
              <FaCheck className={s.check} />
              Everything in Pro for 5 years
            </div>
            <div className={s.benefit}>
              <FaCheck className={s.check} />
              Invitation to weekly design partner meetings
            </div>
            <div className={s.benefit}>
              <FaCheck className={s.check} />
              Access to beta features and our roadmap
            </div>
            <div className={s.benefit}>
              <FaCheck className={s.check} />
              Priority support
            </div>
            <div className={s.price}>
              <div className={s.amount}>$250</div>
              <div className={s.underAmount}>one-time payment</div>
            </div>
            <a href={"/upgrade/believer"}>
              <div className={s.upgradeButton}>Join Us!</div>
            </a>
          </div>
          <div className={s.believerDescription}>
            <b>Who you are: </b>you want everyone to be able to make a living
            off of their passion. News about NFTs, digital goods, and creator
            monetization excite you. You want to to help craft the future of the
            creator economy.
          </div>
        </div>
      </div>
      <Footer props=""></Footer>
    </div>
  );
};

export default Pricing;
