import React, { FunctionComponent, useState, useEffect } from "react";
import NavBar from "./NavBar";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { postWrapper, getWrapper } from "../../helpers/fetch";
import s from "./MyAccount.module.scss";
import { v4 as uuidv4 } from "uuid";
import { Helmet } from "react-helmet";

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore

import { logOut } from "../../actions/user";

const Dashboard: FunctionComponent<{ props: any }> = (props) => {
  const history = useHistory();
  const { pathname } = useLocation();
  const user = useSelector((state: any) => state.user);
  const dispatch = useDispatch();

  type social = "twitter" | "instagram" | "youtube" | "linkedin";

  const [grabLinksAgainFlag, setGrabLinksAgainFlag] = useState("0");

  useEffect(() => {
    getWrapper("GET", `/api/getCampsiteInfo`, {
      Authorization: user.token,
    }).then((getCampsiteInfoResponse) => {
      const social = getCampsiteInfoResponse.campsite.social;

      if ("campsite" in getCampsiteInfoResponse) {
        setSocialSection({ ...social });
        setSiteName(getCampsiteInfoResponse.campsite.siteName);
        setBioHeader(getCampsiteInfoResponse.campsite.bioHeader);
        setBioDescription(getCampsiteInfoResponse.campsite.bioDescription);
        setProfileImageLink(getCampsiteInfoResponse.campsite.profileImageLink);
        setLinkArray(getCampsiteInfoResponse.campsite.links);
        setTheme(getCampsiteInfoResponse.campsite.theme);
      }
    });
  }, [user.token, grabLinksAgainFlag]);

  const setSocialLink = (platform: string, link: string) => {
    setSocialSection({ ...socialSection, [platform]: link });
  };

  // instead of making 3 diff setters, figure out how index signatures work properly
  const updateStateUrl = (el: any, ind: number, url: string) => {
    const copyLinkArray = [...linkArray];
    copyLinkArray[ind].url = url;
    setLinkArray(copyLinkArray);
  };

  const updateStateTitle = (el: any, ind: number, title: string) => {
    const copyLinkArray = [...linkArray];
    copyLinkArray[ind].title = title;
    setLinkArray(copyLinkArray);
  };

  const updateStateDescription = (
    el: any,
    ind: number,
    description: string
  ) => {
    const copyLinkArray = [...linkArray];
    copyLinkArray[ind].description = description;
    setLinkArray(copyLinkArray);
  };

  const updateStateThumbnailLink = (
    el: any,
    ind: number,
    thumbnailLink: string
  ) => {
    const copyLinkArray = [...linkArray];
    copyLinkArray[ind].thumbnailLink = thumbnailLink;
    setLinkArray(copyLinkArray);
  };

  // BEGIN States

  const [siteName, setSiteName] = useState("");
  const [socialSection, setSocialSection] = useState({
    twitter: "",
    instagram: "",
    youtube: "",
    linkedin: "",
  });
  const [previewSize, setPreviewSize] = useState("desktop");
  const [bioHeader, setBioHeader] = useState("");
  const [bioDescription, setBioDescription] = useState("");
  const [profileImageLink, setProfileImageLink] = useState("");
  const [showUploader, setShowUploader] = useState(false);
  const [uploadLocation, setUploadLocation] = useState("");
  // we need this extra piece of state for when we update link thumbnails
  const [uploadLocationIndex, setUploadLocationIndex] = useState(-1);
  const [linkArray, setLinkArray] = useState([
    { url: "", title: "", description: "", id: "", thumbnailLink: "" },
  ]);
  const [newLink, setNewLink] = useState({
    url: "",
    title: "",
    description: "",
    thumbnailLink: "",
    id: uuidv4(),
  });
  const [theme, setTheme] = useState({
    name: "goomy",
    primary: "black",
    secondary: "black",
    tertiary: "black",
    bioColor: "black",
    textColor: "black",
    textHover: "black",
  });

  // END states

  // START api calls
  const updateCampsiteSocial = async (platform: social) => {
    await postWrapper(
      "POST",
      "/api/updateSocial",
      {
        Authorization: user.token,
        "Content-Type": "application/json",
      },
      JSON.stringify({ platform, updatedValue: socialSection[platform] })
    );
  };

  const updateCampsiteBio = async () => {
    await postWrapper(
      "POST",
      "/api/updateBio",
      {
        Authorization: user.token,
        "Content-Type": "application/json",
      },
      JSON.stringify({ bioHeader, bioDescription })
    );
  };

  const updateCampsiteProfImage = async (profileImageLink: string) => {
    await postWrapper(
      "POST",
      "/api/updateProfImage",
      {
        Authorization: user.token,
        "Content-Type": "application/json",
      },
      JSON.stringify({ profileImageLink })
    );
  };

  const updateCampsiteLink = async (index: number, newLinkThumbnail = "") => {
    const copyLinkObj = linkArray[index];
    if (newLinkThumbnail !== "") {
      copyLinkObj.thumbnailLink = newLinkThumbnail;
    }
    await postWrapper(
      "POST",
      "/api/updateLink",
      {
        Authorization: user.token,
        "Content-Type": "application/json",
      },
      JSON.stringify({ linkObj: copyLinkObj })
    );
  };

  const deleteCampsiteLink = async (index: number) => {
    await postWrapper(
      "POST",
      "/api/deleteLink",
      {
        Authorization: user.token,
        "Content-Type": "application/json",
      },
      JSON.stringify({ linkObj: linkArray[index] })
    );
    setGrabLinksAgainFlag(uuidv4());
  };

  const submitNewLink = async () => {
    await postWrapper(
      "POST",
      "/api/addNewLink",
      {
        Authorization: user.token,
        "Content-Type": "application/json",
      },
      JSON.stringify({ newLink })
    );
    setGrabLinksAgainFlag(uuidv4());
    setNewLink({
      url: "",
      title: "",
      description: "",
      thumbnailLink: "",
      id: uuidv4(),
    });
  };

  const updateAllLinkArray = async () => {
    await postWrapper(
      "POST",
      "/api/updateAllLinkArray",
      {
        Authorization: user.token,
        "Content-Type": "application/json",
      },
      JSON.stringify({ linkArray })
    );
  };

  // END api calls
  const renderHelmet = () => {
    return (
      <Helmet>
        <title>Campah – A Space for Everything You Create</title>
        <meta
          name="description"
          content="Content creation happens in a lot of different places, Campah helps you tie it together.  Claim a URL, set up a page in a minute and share your page wherever you want to."
        />
        <meta
          name="keywords"
          content="link in bio, page builder, space for everything you create"
        />
        <meta property="og:url" content="https://www.campah.com/" />
        <meta
          property="og:title"
          content="Campah – A Space for Everything You Create"
        />
        <meta
          property="og:description"
          content="Content creation happens in a lot of different places, Campah helps you tie it together.  Claim a URL, set up a page in a minute and share your page wherever you want to."
        />
        <meta property="og:image" content="https://i.imgur.com/Xb3LCcz.jpeg" />
        <meta
          property="og:image:url"
          content="https://i.imgur.com/Xb3LCcz.jpeg"
        />
        <meta
          property="twitter:image"
          content="https://i.imgur.com/Xb3LCcz.jpeg"
        />
      </Helmet>
    );
  };

  return (
    <div>
      <NavBar></NavBar>
      {renderHelmet()}
      <div className={s.pageContainer}>
        <div className={s.contentContainer}>
          <a href="/dashboard">
            <div className={s.dashboardButton}>Go to Dashboard</div>
          </a>
          <div className={s.pageHeader}>My Account</div>
          <div className={s.accountCard}>
            <div className={s.accountImageContainer}>
              <img
                className={s.accountImage}
                src={user.user_avatar_url}
                alt="profile "
              />
            </div>
            <div>
              <div className={s.headerLabel}>Email</div>
              <div className={s.email}>{user.user_email}</div>
              <div className={s.viaHold}>
                <div
                  onClick={() => {
                    dispatch({ type: "LOG_OUT" });
                    history.push("/");
                  }}
                  className={s.headerLabelRight}
                >
                  LOG OUT
                </div>
              </div>
            </div>
          </div>
          <div className={s.subHeader}>Account Type</div>
          <div className={s.accountType}>Free</div>
          <div className={s.upgradeContainer}>
            <div className={s.upgradeHeader}>Upgrade your account</div>
            <div className={s.upgradeItem}>
              Add unlimited links on your page
            </div>
            <div className={s.upgradeItem}>Pick premium and custom themes</div>
            <div className={s.upgradeItem}>
              Hide the campah logo on your page
            </div>
            <a href="/pricing">
              <div className={s.upgradeButton}>Upgrade</div>
            </a>
          </div>
          <br></br>
          <div>For billing questions - email shah@failflow.com</div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
